import { Spinner } from "react-bootstrap";
import styles from "./index.module.scss";

interface loaderProps {
  isLoading: boolean;
}

export default function ComponentLoader(props: loaderProps) {
  return (
    <div className={styles.loader}>
      <Spinner animation="border" variant="dark" />
    </div>
  );
}
