import { MambuTransactionHoldStatus, USER_STATUSES } from "../constants";

export type IdvType = {
  transaction_id: string;
  user_id: string;
  timestamp: string;
  overall_status: keyof typeof USER_STATUSES;
};

export type IdvRecordsType = IdvType[];

export type IdpType = {
  transaction_id: string;
  user_id: string;
  timestamp: string;
  validation_result: keyof typeof USER_STATUSES;
  front_image_url: string;
  back_image_url: string;
  selfie_image_url: string;
};

export type IdpRecordsType = IdpType[];

export type IdvTransactionType = {
  clientId: string;
  screeningResult: string;
  timestamp: string;
  transactionId: string;
  userId: string;
  verificationResults: string;
};

export type AuthenticationTest = {
  key: string;
  result: string;
};

export type IdpTransactionType = {
  clientId: string;
  screeningResult: string;
  timestamp: string;
  transactionId: string;
  userId: string;
  authenticationTests: AuthenticationTest[];
};

export type Address = {
  country: string | undefined;
  postalCode: string | undefined;
  addressLine1: string | undefined;
  addressLine2: string | undefined;
  addressLine3: string | undefined;
};

export type LivenessType = {
  transaction_id: string;
  user_id: string;
  timestamp: string;
  facial_match_result: {
    score: string;
    isMatch: boolean;
  };
  passive_liveness_result: { score: string; livenessAssessment: string };
  selfie_image_url: string;
};

export type LivenessRecordType = LivenessType[];

export type LivenessTransactionType = {
  clientId: string;
  screeningResult: {
    isMatch: string;
    livenessAssessment: string;
  };
  timestamp: string;
  transactionId: string;
  userId: string;
};

export type PageInfo = {
  totalCount: number;
  totalPage: number;
  pageNumber: number;
  pageSize: number;
};

export enum QueryKeyEnum {
  GetUserMambuDetails = "get-user-mambu-details",
}

export type MambuAuthorizationHold = {
  status?: MambuTransactionHoldStatus;
  holdId: number;
  timestamp: string;
  amount: {
    quantity: string;
    currency: string;
  };
};
