import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { emphasisByStatuses, StatusBadgeTextEnum } from "../../../constants";
import { getVeriffSessions } from "../../../services/userServices";
import { VeriffSessionType } from "../../../types/user";
import ErrorPage from "../../ErrorPage";
import PageLoader from "../../PageLoader";
import StatusBadge from "../../StatusBadge";
import PaginationComponent from "../../Pagination";
import { useMemo, useState } from "react";

import styles from "./index.module.scss";

const sizeOfPage = 10;

export default function VeriffSessionTable() {
  const { userId } = useParams();

  const { isLoading, error, data } = useQuery<VeriffSessionType[], Error>(
    ["get-veriff-sessions", userId],
    () => getVeriffSessions(userId || "")
  );
  const [currentPage, setCurrentPage] = useState(1);

  const veriffSessionFilter = useMemo(() => {
    if (!!data?.length) {
      return data.slice(
        (currentPage - 1) * sizeOfPage,
        currentPage * sizeOfPage
      );
    }
    return [];
  }, [currentPage, data]);

  if (isLoading)
    return (
      <PageLoader isLoading={isLoading} content="Loading veriff sessions.." />
    );

  if (error || !data) return <ErrorPage />;

  return (
    <div>
      <div className={styles.boxExternalPagination}>
        <span className={styles.listGroupTitle}>Veriff sessions</span>
        <PaginationComponent
          pageCount={Math.ceil(data.length / sizeOfPage)}
          currentPage={currentPage}
          itemOfPage={veriffSessionFilter.length}
          totalItems={data.length}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div className={styles.table}>
        <Table hover bordered responsive="md">
          <thead>
            <tr>
              <th>Session ID</th>
              <th>Initiated On</th>
              <th>Submission Count</th>
              <th>Proof of Address</th>
              <th>Verification</th>
              <th>Sanctions</th>
              <th>Veriff Station</th>
            </tr>
          </thead>
          <tbody>
            {veriffSessionFilter.length > 0 ? (
              veriffSessionFilter.map((veriffSession: VeriffSessionType) => (
                <tr key={veriffSession.sessionId}>
                  <td>{veriffSession.sessionId}</td>
                  <td>{veriffSession.initiatedOn}</td>
                  <td>{veriffSession.submissionCount}</td>
                  <td>
                    <StatusBadge
                      status={
                        StatusBadgeTextEnum[veriffSession.proofOfAddressOutcome]
                      }
                      emphasis={
                        emphasisByStatuses.proof_of_address_status[
                          veriffSession.proofOfAddressOutcome
                        ]
                      }
                    />
                  </td>
                  <td>
                    <StatusBadge
                      status={
                        StatusBadgeTextEnum[veriffSession.verificationOutcome]
                      }
                      emphasis={
                        emphasisByStatuses.verification_status[
                          veriffSession.verificationOutcome
                        ]
                      }
                    />
                  </td>
                  <td>
                    <StatusBadge
                      status={
                        StatusBadgeTextEnum[veriffSession.sanctionsOutcome]
                      }
                      emphasis={
                        emphasisByStatuses.sanctions_status[
                          veriffSession.sanctionsOutcome
                        ]
                      }
                    />
                  </td>
                  <td>
                    <a
                      className={styles.veriffStationLink}
                      href={`https://station.veriff.com/verifications/${veriffSession.sessionId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://station.veriff.com/verifications/
                      {veriffSession.sessionId}
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className={styles.emptyTable}>
                  Oops, no results were found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
