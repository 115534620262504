import axios from "axios";
import { getAccessJwtToken } from "./auth";

const defaultOptions = {
  baseURL: process.env.REACT_APP_BASE_URL,
};

// Create instance
const instance = axios.create(defaultOptions);

// Set the AUTH token for any request
instance.interceptors.request.use(async (config) => {
  const token = await getAccessJwtToken();
  config.headers!.Authorization = token ? `Bearer ${token}` : "";

  return config;
});

export default instance;
