import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import ProofOfAddressConsole from "../../../screens/proofOfAddressConsole";
import VeriffSessionTable from "../VeriffSessionTable";
import {
  getProofOfAddress,
  getUser,
  approveProofOfAddress,
  updateSanctionsOutcome,
  getAuditLog,
} from "../../../services/userServices";
import SanctionsOutcome from "../../../screens/sanctionsOutcome";
import { responsesFuncMap } from "../../../services/storedResponseServices";
import NoDataComponent from "../../NoDataComponent";
import DynamicTable from "../../DynamicTable";
import ComponentLoader from "../../ComponentLoader";

import styles from "./index.module.scss";
import HoldsTable from "../HoldsTable";
import AliasTable from "../AliasTable";

export const transformHeadersMap: Partial<
  Record<keyof typeof responsesFuncMap, Record<string, string>>
> = {
  CRS: { tin: "TIN", country: "Country", noTinIssuedReason: "No TIN Reason" },
};

enum TabsKeyEnum {
  VERIFF_SESSIONS = "",
  PROOF_OF_ADDRESS = "ProofOfAddress",
  SANCTIONS_OUTCOME = "SanctionsOutcome",
  CKA = "CKA",
  AI = "AI",
  CRS = "CRS",
  HOLDS = "Holds",
  ALIAS = "Alias",
}

const UserTabDetail = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { hash } = useLocation();

  const [selectedTableRecords, setSelectedTableRecords] = useState([]);
  const [selectedTable, setSelectedTable] = useState("");
  const [isTableLoading, setIsTableLoading] = useState(false);

  const mappedHeaders =
    transformHeadersMap[selectedTable as keyof typeof transformHeadersMap];

  const transformedData = useMemo(() => {
    if (!mappedHeaders) return selectedTableRecords;

    return selectedTableRecords.map((record) =>
      Object.assign(
        {},
        ...Object.keys(mappedHeaders).map((header) => ({
          [mappedHeaders[header]]: record[header],
        }))
      )
    );
  }, [mappedHeaders, selectedTableRecords]);

  const setData = useCallback(
    async (table: string) => {
      try {
        setIsTableLoading(true);
        setSelectedTable(table);
        setSelectedTableRecords([]);
        if (["CKA", "AI", "CRS"].includes(table)) {
          const result = await responsesFuncMap[
            table as keyof typeof responsesFuncMap
          ](userId || "")!;
          setSelectedTableRecords(result);
        }
        setIsTableLoading(false);
      } catch (error) {
        setIsTableLoading(false);
        setSelectedTableRecords([]);
      }
    },
    [userId]
  );

  const transformedHeaders = useMemo(() => {
    if (!selectedTableRecords[0]) return [];

    return mappedHeaders
      ? Object.values(mappedHeaders)
      : Object.keys(selectedTableRecords[0]);
  }, [mappedHeaders, selectedTableRecords]);

  const tabWithDynamicTable = useMemo(() => {
    return (
      <div
        className={selectedTableRecords.length === 0 ? styles.tableLoader : ""}
      >
        <div className={styles.loader}>
          {isTableLoading && <ComponentLoader isLoading={isTableLoading} />}
        </div>
        {selectedTableRecords.length === 0 && !isTableLoading && (
          <NoDataComponent />
        )}
        {transformedData?.length !== 0 && !isTableLoading && (
          <div className={styles.dynamicTable}>
            <div className={styles.listGroupTitle}>Stored Response</div>
            <DynamicTable
              data={transformedData}
              selectedTable={selectedTable}
              headers={transformedHeaders}
            />
          </div>
        )}
      </div>
    );
  }, [
    selectedTableRecords.length,
    isTableLoading,
    transformedData,
    selectedTable,
    transformedHeaders,
  ]);

  useEffect(() => {
    const listener = () => {
      navigate(0);
    };

    window.addEventListener("hashchange", listener, false);
    return () => {
      window.removeEventListener("hashchange", listener);
    };
  }, [hash, navigate]);

  return (
    <>
      <Tabs
        defaultActiveKey={hash.slice(1)}
        id="fill-tab-example"
        className={styles.userTabDetail}
        fill
        onSelect={(activeKey) => {
          setData(activeKey || "");
          navigate(`#${activeKey}`);
        }}
      >
        <Tab
          eventKey={TabsKeyEnum.VERIFF_SESSIONS}
          title="Veriff sessions"
          className={styles.userTabDetailItem}
        >
          <VeriffSessionTable />
        </Tab>
        <Tab
          eventKey={TabsKeyEnum.ALIAS}
          title="Alias"
          className={styles.userTabDetailItem}
        >
          <AliasTable />
        </Tab>
        <Tab
          eventKey={TabsKeyEnum.PROOF_OF_ADDRESS}
          title="Proof of address"
          className={styles.userTabDetailItem}
        >
          <ProofOfAddressConsole
            getUser={getUser}
            getProofOfAddress={getProofOfAddress}
            getAuditLog={getAuditLog}
            approve={approveProofOfAddress}
          />
        </Tab>
        <Tab
          eventKey={TabsKeyEnum.SANCTIONS_OUTCOME}
          title="Sanctions outcome"
          className={styles.userTabDetailItem}
        >
          <SanctionsOutcome
            getUser={getUser}
            getAuditLog={getAuditLog}
            updateSanctionsOutcome={updateSanctionsOutcome}
          />
        </Tab>
        <Tab
          eventKey={TabsKeyEnum.CKA}
          title="CKA"
          className={styles.userTabDetailItem}
        >
          {tabWithDynamicTable}
        </Tab>
        <Tab
          eventKey={TabsKeyEnum.AI}
          title="AI"
          className={styles.userTabDetailItem}
        >
          {tabWithDynamicTable}
        </Tab>
        <Tab
          eventKey={TabsKeyEnum.CRS}
          title="CRS"
          className={styles.userTabDetailItem}
        >
          {tabWithDynamicTable}
        </Tab>
        <Tab
          eventKey={TabsKeyEnum.HOLDS}
          title="Holds"
          className={styles.userTabDetailItem}
        >
          <HoldsTable />
        </Tab>
      </Tabs>
    </>
  );
};

export default UserTabDetail;
