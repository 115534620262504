import { Badge } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";

import { StatusBadgeTextEnum } from "../../constants";

import styles from "./index.module.scss";

interface badgeProps {
  status: string;
  emphasis: Variant;
  className?: string;
}

export default function statusBadge({
  emphasis,
  status,
  className = "",
}: badgeProps) {
  return (
    <Badge
      bg={emphasis}
      className={`${styles[emphasis]} ${className} ${
        status === StatusBadgeTextEnum.RESUBMISSION_REQUIRED &&
        styles.resubmissionRequired
      }`}
    >
      {status}
    </Badge>
  );
}
