import { Auth } from "aws-amplify";

export const getAccessJwtToken = async () => {
  // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
  try {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (e) {
    return null;
  }
};
