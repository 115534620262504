import { Modal } from "react-bootstrap";
import ComponentLoader from "../ComponentLoader";
import styles from "./index.module.scss";

interface loaderProps {
  isLoading: boolean;
  content: string;
}

export default function PageLoader(props: loaderProps) {
  return (
    <Modal
      show={props.isLoading}
      backdrop="static"
      keyboard={false}
      contentClassName={styles.pageLoader}
      centered
    >
      <Modal.Body>
        <ComponentLoader isLoading />
        <div className={styles.content}> {props.content} </div>
      </Modal.Body>
    </Modal>
  );
}
