import styles from "./index.module.scss";
import clear from "../../assets/icons/clear.svg";
import { useState } from "react";
interface TextFieldProps {
  label: string;
  value: string;
  autoFocus?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
}

export default function TextField({
  label,
  autoFocus = false,
  value,
  onChange,
}: TextFieldProps) {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange(event.target.value);

  const [isFocus, setIsFocus] = useState(autoFocus);
  return (
    <div className={styles.inputWrap}>
      <input
        type="text"
        className={value ? styles.filledOutline : ""}
        value={value}
        required
        autoFocus={autoFocus}
        onFocus={() => setIsFocus(true)}
        onBlur={() => {
          setTimeout(() => {
            setIsFocus(false);
          }, 100);
        }}
        onChange={handleOnChange}
      />
      <label>{label}</label>
      {isFocus && (
        <img
          src={clear}
          alt="clear-icon"
          className={styles.clearIcon}
          onClick={() => onChange("")}
        />
      )}
    </div>
  );
}
