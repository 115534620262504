import { PageInfo } from ".";
import {
  AiStatuses,
  CkaStatuses,
  CrsStatuses,
  ProofOfAddressStatuses,
  VerificationStatuses,
  VerificationSubStatuses,
  SanctionsStatuses,
  FnaStatus,
  EddStatus,
  AdverseMediaStatus,
  PepScreeningStatus,
} from "../constants";

export type UsersType = {
  users: UserType[];
  pageInfo?: PageInfo;
};

export type UserType = {
  addr_country: string;
  addr_line_1: string;
  addr_line_2?: string;
  addr_line_3?: string;
  addr_postal: string;
  created_at?: string;
  date_of_birth?: string;
  email?: string;
  full_name: string;
  id?: string;
  id_number?: string;
  mobile: string;
  mobile_country_code: string;
  nationality?: string;
  phone_number_verified?: boolean;
  sex?: string;
  total_count: number;
  updated_at?: string;
  transaction_id?: string;
  front_image_url?: string;
  ai_status: AiStatuses;
  cka_status: CkaStatuses;
  crs_status: CrsStatuses;
  session_id: string;
  session_url: string;
  session_token: string;
  initiated_on: string;
  submission_count: number;
  proof_of_address_status: ProofOfAddressStatuses;
  verification_status: VerificationStatuses;
  verification_sub_status: VerificationSubStatuses | null;
  sanctions_status: SanctionsStatuses;
  faUserType: "CUSTOMER" | "FA";
  fnaStatus: FnaStatus;
  fna_accept_recommendation_date?: string;
  is_disabled?: boolean;
  edd_status: EddStatus;
  adverse_media_status: AdverseMediaStatus;
  pep_screening_status: PepScreeningStatus;
  kyc_passed: boolean;
  changeManager: {
    accepted?: boolean;
    decisionDate?: string;
  };
};

export type UserMambuDetails = {
  mambuClientProfileLink: string;
};
export interface VeriffSessionType {
  sessionId: string;
  sessionUrl: string;
  sessionToken: string;
  userId: string;
  initiatedOn: string;
  submissionCount: number;
  proofOfAddressOutcome: ProofOfAddressStatuses;
  verificationOutcome: VerificationStatuses;
  sanctionsOutcome: SanctionsStatuses;
}

export const isProofOfAddressApprovable = (user: UserType) =>
  user.proof_of_address_status === "PENDING_REVIEW";

export const hasAnApprovedAddress = (user: UserType) =>
  ["EXEMPT", "POSITIVE"].includes(user.proof_of_address_status);

export enum KycAttributeType {
  PROOF_OF_ADDRESS = "proof-of-address",
  SANCTIONS = "sanctions",
}

export interface AuditLogType {
  userId: string;
  adminEmail: string;
  attribute: string;
  override: string;
  updatedAt: string;
}

export interface AliasType {
  id: number;
  userId: string;
  knownName: string;
  createdAt: string;
  requestBy?: string;
  proofOfDocument?: string;
}
