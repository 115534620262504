import { memo } from "react";
import { useQuery } from "react-query";
import axios from "axios";

import ComponentLoader from "../../ComponentLoader";
import { getUserMambuDetails } from "../../../services/userServices";
import { UserMambuDetails } from "../../../types/user";
import ErrorPage from "../../ErrorPage";
import { QueryKeyEnum } from "../../../types";
import arrowNewTab from "../../../assets/icons/arrow-new-tab.svg";
import { HTTP_CODES } from "../../../constants";

import styles from "../../../styles/user.module.scss";

export interface MambuDetailsProps {
  userId?: string;
}

function MambuDetails(props: MambuDetailsProps) {
  const { isLoading, error, data } = useQuery<UserMambuDetails, Error>(
    [QueryKeyEnum.GetUserMambuDetails, props.userId],
    () => getUserMambuDetails(props.userId || "")
  );

  if (isLoading) return <ComponentLoader isLoading={isLoading} />;

  if (
    axios.isAxiosError(error) &&
    error.response?.status === HTTP_CODES.NOT_FOUND
  ) {
    return null;
  }

  if (error || !data) return <ErrorPage />;

  return (
    <>
      {data && data.mambuClientProfileLink ? (
        <a
          className={styles.titleRight}
          target="_blank"
          href={data.mambuClientProfileLink}
          rel="noreferrer"
        >
          <span>User’s Mambu dashboard</span>
          <img src={arrowNewTab} alt="arrow" />
        </a>
      ) : null}
    </>
  );
}

export default memo(MambuDetails);
