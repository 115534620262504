import axios from "../helpers/axios";

export const getAIResponses = async (userId: string) => {
  const { data } = await axios.get(`/ai-responses/${userId}`);
  return data;
};

export const getCKAResponses = async (userId: string) => {
  const { data } = await axios.get(`/cka-responses/${userId}`);
  return data;
};

export const getIdpResponses = async (userId: string) => {
  const { data } = await axios.get(`/idp-responses/${userId}`);
  return data;
};

export const getIdvResponses = async (userId: string) => {
  const { data } = await axios.get(`/idv-responses/${userId}`);
  return data;
};

export const getScreeningResponses = async (userId: string) => {
  const { data } = await axios.get(`/screening-responses/${userId}`);
  return data;
};

export const getLivenessResponses = async (userId: string) => {
  const { data } = await axios.get(`/liveness-responses/${userId}`);
  return data;
};

export const getProofOfAddressResponses = async (userId: string) => {
  const { data } = await axios.get(`/proof-of-address-responses/${userId}`);
  return data;
};

export const getResponseFromService = async (transactionId: string) => {
  const { data } = await axios.get(
    `/kyc/refinitiv/transactions/${transactionId}`
  );
  return data;
};

export const getCRSResponses = async (userId: string) => {
  const { data } = await axios.get(`/tax-info/${userId}`);
  return data;
};

export const responsesFuncMap = {
  AI: getAIResponses,
  CKA: getCKAResponses,
  IDP: getIdpResponses,
  IDV: getIdvResponses,
  SCREENING: getScreeningResponses,
  LIVENESS: getLivenessResponses,
  PROOF_OF_ADDRESS: getProofOfAddressResponses,
  CRS: getCRSResponses,
};
