import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import Layout from "./components/layout";
import Home from "./screens/home";
import Users from "./screens/users";
import User from "./screens/user";

interface AppProps {
  // Props from withAuthenticator - not clear if there is a TS definition for it
  signOut?: () => void;
}

const queryClient = new QueryClient();

const App = (props: AppProps | undefined) => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout signOut={props?.signOut} />}>
            <Route index element={<Home />} />
            <Route path="users">
              <Route index element={<Users />} />
              <Route path=":userId">
                <Route index element={<User />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default withAuthenticator(App, { hideSignUp: true });
