import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TextField from "../../TextField";
import { addCollectionAlias } from "../../../services/userServices";
import axios from "axios";

import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { HTTP_CODES } from "../../../constants";
import { AliasType } from "../../../types/user";

interface IAddAliasNameModal {
  isVisible: boolean;
  onClose: () => void;
  onSubmitted: (alias: AliasType) => void;
}

interface AddCollectionAliasQuery {
  userId: string;
  alias: string;
  driveLink?: string;
}

const duplicateCollectionAliasErrorCode = "DuplicateCollectionAliasError";

export default function AddAliasNameModal({
  onClose,
  onSubmitted,
  isVisible = false,
}: IAddAliasNameModal) {
  const { userId } = useParams();

  const [alias, setAlias] = useState("");
  const [driveLink, setDriveLink] = useState("");
  const [isAliasExisted, setIsAliasExisted] = useState(false);

  useEffect(() => {
    setAlias("");
    setDriveLink("");
    setIsAliasExisted(false);
  }, [isVisible]);

  const mutation = useMutation({
    mutationFn: ({ userId, alias, driveLink }: AddCollectionAliasQuery) =>
      addCollectionAlias(userId, alias, driveLink),
    onError: (
      error: AxiosError<{
        errorCode: string;
        errorMessage: string;
      }>
    ) => {
      if (
        axios.isAxiosError(error) &&
        error.response?.status === HTTP_CODES.CLIENT_ERROR &&
        error.response.data.errorCode === duplicateCollectionAliasErrorCode
      ) {
        setIsAliasExisted(true);
      } else {
        toast.error(
          `An error happened. Please reach out to the engineering team with the following data: error: ${error.message}, userId: ${userId}`
        );
        onClose();
      }
    },
    onSuccess: async (data) => {
      await onSubmitted(data);
      onClose();
    },
  });

  const onSubmitAlias = async () => {
    mutation.mutate({
      userId: userId || "",
      alias,
      driveLink: driveLink || undefined,
    });
  };

  return (
    <Modal
      id="addAliasNameModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      contentClassName="w-auto"
      show={isVisible}
      onHide={onClose}
    >
      <Modal.Body>
        <div className={styles.modalContainer}>
          <h2 className={styles.modalTitle}>Add Alias</h2>
          <div className={styles.modalBody}>
            <div className={styles.aliasContent}>
              <TextField
                value={alias}
                label={"Alias"}
                autoFocus={true}
                onChange={(value) => {
                  setAlias(value);
                  setIsAliasExisted(false);
                }}
              />
              {isAliasExisted && (
                <span>An alias with the same name has already been added.</span>
              )}
            </div>
            <TextField
              value={driveLink}
              label={"Drive link for image proof"}
              onChange={setDriveLink}
            />
          </div>
          <div className={styles.modalFooter}>
            <Button
              className={styles.submitButton}
              variant="success"
              disabled={alias && !isAliasExisted ? false : true}
              size="lg"
              onClick={onSubmitAlias}
            >
              Save changes
            </Button>
            <Button
              className={styles.cancelButton}
              variant="success"
              size="lg"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
