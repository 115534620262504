import {
  Button,
  Card,
  Col,
  Container,
  Image,
  ListGroup,
  Row,
} from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useCallback } from "react";
import { DateTime } from "luxon";

import {
  isProofOfAddressApprovable,
  hasAnApprovedAddress,
  UserType,
  AuditLogType,
  KycAttributeType,
} from "../types/user";
import ErrorPage from "../components/ErrorPage";
import ComponentLoader from "../components/ComponentLoader";

import styles from "../styles/user.module.scss";

type ProofViewerProps = {
  sessionId: string;
  getProofOfAddress: (sessionId: UserType["session_id"]) => Promise<string>;
};

const ProofViewer = (props: ProofViewerProps) => {
  const { isLoading, error, data } = useQuery(
    ["get-proof-of-address", props.sessionId],
    () => props.getProofOfAddress(props.sessionId)
  );

  if (isLoading) {
    return <ComponentLoader isLoading={isLoading} />;
  }

  if (error || !data) {
    return <ErrorPage />;
  }

  return <Image fluid={true} src={`data:image/jpeg;base64, ${data}`} />;
};

type AddressViewerProps = {
  person: string;
  address: {
    addr_line_1: string;
    addr_line_2: string | undefined;
    addr_line_3: string | undefined;
    addr_postal: string;
    addr_country: string;
  };
  isApprovable: boolean;
  isApproved: boolean;
  approve: () => Promise<void>;
};

const ApprovedSuccessfully = () => {
  return (
    <div>
      <span data-testid="addr_approved_indicator">Approved successfully</span>
    </div>
  );
};

const ApprovalInProgress = () => {
  return <span>Approving...</span>;
};

const ApprovalFailed = ({ onClick }: { onClick: () => void }) => {
  return (
    <div>
      <Button
        variant="primary"
        data-testid="addr_approval_link"
        onClick={onClick}
      >
        Approve
      </Button>
      <span>Approval failed!</span>
    </div>
  );
};

const Approved = () => {
  return (
    <div>
      <span data-testid="addr_approved_indicator">Approved</span>
    </div>
  );
};

const Approvable = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      variant="primary"
      data-testid="addr_approval_link"
      onClick={onClick}
    >
      Approve
    </Button>
  );
};

const NotApprovableYet = () => {
  return (
    <div>
      <span>Not yet approvable.</span>
    </div>
  );
};

const AddressViewer = (props: AddressViewerProps) => {
  const queryClient = useQueryClient();

  const { userId } = useParams();

  const mutation = useMutation({
    mutationFn: () => props.approve(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["get-poa-audit-log", userId],
      });
    },
  });

  return (
    <Card bg="light">
      <Card.Body>
        <Card.Title data-testid="full_name">{props.person}</Card.Title>
      </Card.Body>
      <ListGroup variant="flush">
        <ListGroup.Item data-testid="addr_line_1">
          {props.address.addr_line_1}
        </ListGroup.Item>
        <ListGroup.Item data-testid="addr_line_2">
          {props.address.addr_line_2}
        </ListGroup.Item>
        <ListGroup.Item data-testid="addr_line_3">
          {props.address.addr_line_3}
        </ListGroup.Item>
        <ListGroup.Item data-testid="addr_postal">
          {props.address.addr_postal}
        </ListGroup.Item>
        <ListGroup.Item data-testid="addr_country">
          {props.address.addr_country}
        </ListGroup.Item>
      </ListGroup>
      <Card.Footer>
        {mutation.isSuccess ? (
          <ApprovedSuccessfully />
        ) : mutation.isLoading ? (
          <ApprovalInProgress />
        ) : mutation.isError ? (
          <ApprovalFailed onClick={() => mutation.mutate()} />
        ) : props.isApproved ? (
          <Approved />
        ) : props.isApprovable ? (
          <Approvable onClick={() => mutation.mutate()} />
        ) : (
          <NotApprovableYet />
        )}
      </Card.Footer>
    </Card>
  );
};

type ProofOfAddressConsoleProps = {
  getUser: (userId: NonNullable<UserType["id"]>) => Promise<UserType>;
  getAuditLog: (
    userId: NonNullable<UserType["id"]>,
    kycAttribute: KycAttributeType
  ) => Promise<AuditLogType[]>;
  getProofOfAddress: (sessionId: UserType["session_id"]) => Promise<string>;
  approve: (sessionId: UserType["session_id"]) => Promise<void>;
};

const ProofOfAddressConsole = (props: ProofOfAddressConsoleProps) => {
  const { userId } = useParams();

  const { error, data, isLoading } = useQuery<UserType, Error>(
    ["get-user-details", userId],
    () => props.getUser(userId!)
  );

  const {
    error: auditLogError,
    data: auditLog,
    isLoading: isAuditLogLoading,
  } = useQuery<AuditLogType[], Error>(["get-poa-audit-log", userId], () =>
    props.getAuditLog(userId!, KycAttributeType.PROOF_OF_ADDRESS)
  );

  const formatedLog = useCallback((log: AuditLogType) => {
    const formatedDate = DateTime.fromISO(log.updatedAt).toFormat("d MMM yyyy");
    const formatedTime = DateTime.fromISO(log.updatedAt).toFormat("tt");

    return `${
      log.adminEmail
    } updated the Proof of Address status to "${log.override.toUpperCase()}" on ${formatedDate} at ${formatedTime}`;
  }, []);

  if (isLoading || isAuditLogLoading) {
    return (
      <div className={styles.loader}>
        <ComponentLoader isLoading={isLoading} />
      </div>
    );
  }

  if (error || auditLogError || !data || !auditLog) {
    return <ErrorPage />;
  }

  const {
    full_name,
    addr_line_1,
    addr_line_2,
    addr_line_3,
    addr_postal,
    addr_country,
  } = data as UserType;

  if (data.proof_of_address_status === "EXEMPT") {
    return (
      <Container fluid data-testid="poaExemption">
        <Row>
          <Col>
            <p>
              {full_name} is <b>exempted</b> from providing a proof of address.
            </p>
            <p>Users verified with SingPass are exempted.</p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Row>
        <Col md={3}>
          <AddressViewer
            isApprovable={isProofOfAddressApprovable(data)}
            isApproved={hasAnApprovedAddress(data)}
            approve={() => props.approve(data.session_id)}
            person={full_name}
            address={{
              addr_line_1,
              addr_line_2,
              addr_line_3,
              addr_postal,
              addr_country,
            }}
          />
        </Col>
        <Col md={9}>
          {isProofOfAddressApprovable(data) || hasAnApprovedAddress(data) ? (
            <ProofViewer
              sessionId={data.session_id}
              getProofOfAddress={props.getProofOfAddress}
            />
          ) : (
            <p>{full_name} has not submitted a proof of address yet.</p>
          )}
        </Col>
      </Row>
      <Row>
        {/* audit log */}
        <ListGroup className="mt-4">
          <ListGroup.Item className={styles.listGroupCustomTitle}>
            Audit log
          </ListGroup.Item>
          {auditLog.map((log, index) => (
            <ListGroup.Item
              id={`${index}-${log.updatedAt}`}
              data-testid={`auditLog-${log.updatedAt}`}
            >
              {formatedLog(log)}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Row>
    </>
  );
};

export default ProofOfAddressConsole;
