import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Button, ListGroup } from "react-bootstrap";
import { useCallback } from "react";
import { DateTime } from "luxon";

import ErrorPage from "../components/ErrorPage";
import { AuditLogType, KycAttributeType, UserType } from "../types/user";

import styles from "../styles/user.module.scss";
import ComponentLoader from "../components/ComponentLoader";

export type SanctionsOutcomeType = "accept" | "reject";

type SanctionsOutcomeProps = {
  getUser: (userId: NonNullable<UserType["id"]>) => Promise<UserType>;
  getAuditLog: (
    userId: NonNullable<UserType["id"]>,
    kycAttribute: KycAttributeType
  ) => Promise<AuditLogType[]>;
  updateSanctionsOutcome: (
    sessionId: UserType["session_id"],
    sanctionsOutcome: SanctionsOutcomeType
  ) => Promise<void>;
};

const SanctionsOutcome = (props: SanctionsOutcomeProps) => {
  const { userId } = useParams();

  const queryClient = useQueryClient();

  const { error, data, isLoading } = useQuery<UserType, Error>(
    ["get-user-details", userId],
    () => props.getUser(userId!)
  );

  const {
    error: auditLogError,
    data: auditLog,
    isLoading: isAuditLogLoading,
  } = useQuery<AuditLogType[], Error>(["get-sanctions-audit-log", userId], () =>
    props.getAuditLog(userId!, KycAttributeType.SANCTIONS)
  );

  const mutation = useMutation({
    mutationFn: ({
      session_id,
      sanctionsOutcome,
    }: {
      session_id: UserType["session_id"];
      sanctionsOutcome: SanctionsOutcomeType;
    }) => props.updateSanctionsOutcome(session_id, sanctionsOutcome),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get-user-details", userId] });
      queryClient.invalidateQueries({
        queryKey: ["get-sanctions-audit-log", userId],
      });
    },
  });

  const formatedLog = useCallback((log: AuditLogType) => {
    const formatedDate = DateTime.fromISO(log.updatedAt).toFormat("d MMM yyyy");
    const formatedTime = DateTime.fromISO(log.updatedAt).toFormat("tt");

    return `${
      log.adminEmail
    } updated the Sanctions Outcome status to "${log.override.toUpperCase()}" on ${formatedDate} at ${formatedTime}`;
  }, []);

  if (isLoading || isAuditLogLoading) {
    return (
      <div className={styles.loader}>
        <ComponentLoader isLoading={isLoading} />
      </div>
    );
  }

  if (error || auditLogError || !data || !auditLog) {
    return <ErrorPage />;
  }

  const { sanctions_status, full_name, session_id } = data as UserType;

  const sanctionsText = {
    NOT_STARTED: `${full_name} has not started their screening process yet.`,
    ACCEPT: `${full_name} has passed their screening.`,
    REJECT: `${full_name} has failed their screening.`,
    PENDING_REVIEW: `${full_name} requires a review of their screening result.`,
  };

  const handleClickSanctionsButton =
    (sanctionsOutcome: SanctionsOutcomeType) => () => {
      mutation.mutate({ session_id, sanctionsOutcome });
    };

  return (
    <div>
      {/* Sanctions outcome text */}
      <p>{full_name && sanctionsText[sanctions_status]}</p>

      {/* Veriff link */}
      {sanctions_status !== "NOT_STARTED" && session_id && (
        <div>
          <a
            href={`https://station.veriff.com/verifications/${session_id}`}
            target="_blank"
            rel="noreferrer"
          >
            https://station.veriff.com/verifications/{session_id}
          </a>
        </div>
      )}

      {/* Accept & Reject button */}
      {sanctions_status === "PENDING_REVIEW" && (
        <span>
          <Button
            className="mt-4 btn"
            variant="success"
            onClick={handleClickSanctionsButton("accept")}
          >
            ACCEPT
          </Button>
          <Button
            className="ms-3 mt-4 btn"
            variant="danger"
            onClick={handleClickSanctionsButton("reject")}
          >
            REJECT
          </Button>
        </span>
      )}
      {/* audit log */}
      <ListGroup className="mt-4">
        <ListGroup.Item className={styles.listGroupCustomTitle}>
          Audit log
        </ListGroup.Item>
        {auditLog.map((log, index) => (
          <ListGroup.Item
            id={`${index}-${log.updatedAt}`}
            data-testid={`auditLog-${log.updatedAt}`}
          >
            {formatedLog(log)}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default SanctionsOutcome;
