import { Variant } from "react-bootstrap/esm/types";

const BADGE_SUCCESS = "success";
const BADGE_SECONDARY = "secondary";
const BADGE_INFO = "info";
const BADGE_WARNING = "warning";
const BADGE_DANGER = "danger";

export const USER_STATUSES = {
  VERIFIED: BADGE_SUCCESS,
  UNVERIFIED: BADGE_SECONDARY,
  SCREENED: BADGE_SUCCESS,
  UNSCREENED: BADGE_SECONDARY,
  PASSED: BADGE_SUCCESS,
  FAILED: BADGE_DANGER,
  AI: BADGE_SUCCESS,
  NON_AI: BADGE_DANGER,
  DECLARED: BADGE_SUCCESS,
  UNDECLARED: BADGE_SECONDARY,
  EXPIRED: BADGE_WARNING,
  EXEMPT: BADGE_INFO,
  EXEMPTED: BADGE_INFO,
  PENDING_REVIEW: BADGE_WARNING,
  NOT_TAKEN: BADGE_SECONDARY,
};

export const PAGE_LIMIT = 50;

export const PAGE_SIZE_DEFAULT = 10;

export const DEFAULT_CURRENCY = "SGD";

export const STATUS_TYPES = {
  IDV: [
    "VERIFIED",
    "EXPIRED",
    "FAILED",
    "UNVERIFIED",
    "EXEMPT",
    "PENDING_REVIEW",
  ],
  IDP: ["UNVERIFIED", "VERIFIED", "PENDING_REVIEW", "FAILED", "EXEMPTED"],
  SCREENING: [
    "SCREENED",
    "EXPIRED",
    "FAILED",
    "UNSCREENED",
    "EXEMPT",
    "PENDING_REVIEW",
  ],
  LIVENESS: ["NOT_TAKEN", "PENDING_REVIEW", "EXEMPT", "PASSED", "FAILED"],
  PROOF_OF_ADDRESS: [
    "NOT_TAKEN",
    "PENDING_REVIEW",
    "EXEMPT",
    "PASSED",
    "FAILED",
  ],
  CRS: ["DECLARED", "UNDECLARED"],
};

export type EmphasisBy<T extends string | number | symbol> = Record<T, Variant>;

export type VerificationStatuses =
  | "NOT_STARTED"
  | "STARTED"
  | "PENDING_REVIEW"
  | "POSITIVE"
  | "NEGATIVE"
  | "RESUBMISSION_REQUIRED";

export type VerificationStatusCategory = EmphasisBy<VerificationStatuses>;

export type VerificationSubStatuses =
  | "SUSPICIOUS_BEHAVIOR"
  | "KNOWN_FRAUD"
  | "RESTRICTED_IP_LOCATION"
  | "MULTIPLE_PARTIES_PRESENT_IN_SESSION"
  | "VELOCITY_OR_ABUSE_DUPLICATED_USER"
  | "VELOCITY_OR_ABUSE_DUPLICATED_ID"
  | "VELOCITY_OR_ABUSE_DUPLICATED_DEVICE"
  | "ATTEMPTED_DECEIT"
  | "ATTEMPTED_DECEIT_DEVICE_SCREEN_USED"
  | "ATTEMPTED_DECEIT_PRINTOUT_USED"
  | "ATTEMPTED_DECEIT_DEVICE_SCREEN_USED_FOR_FACE_IMAGE"
  | "ATTEMPTED_DECEIT_PHOTOS_STREAMED"
  | "SUSPECTED_DOCUMENT_TAMPERING"
  | "DOCUMENT_TAMPERED_DATA_CROSS_REFERENCE"
  | "DOCUMENT_TAMPERED_DOCUMENT_SIMILAR_TO_SPECIMEN"
  | "PERSON_SHOWING_DOCUMENT_MISMATCH"
  | "VIDEO_OR_PHOTO_MISSING"
  | "POOR_IMAGE_QUALITY"
  | "VIDEO_MISSING"
  | "FACE_IMAGE_MISSING"
  | "FACE_NOT_CLEAR"
  | "DOCUMENT_DAMAGED"
  | "DOCUMENT_TYPE_NOT_SUPPORTED"
  | "DOCUMENT_EXPIRED"
  | "DOCUMENTS_PERSON_MISMATCH"
  | "DOCUMENT_FRONT_MISSING"
  | "DOCUMENT_BACK_MISSING"
  | "DOCUMENT_FRONT_NOT_FULLY_IN_FRAME"
  | "DOCUMENT_BACK_NOT_FULLY_IN_FRAME"
  | "DOCUMENT_DATA_NOT_VISIBLE"
  | "UNABLE_TO_COLLECT_SURNAME"
  | "UNABLE_TO_COLLECT_FIRST_NAME"
  | "UNABLE_TO_COLLECT_DOB"
  | "UNABLE_TO_COLLECT_ISSUE_DATE"
  | "UNABLE_TO_COLLECT_EXPIRY_DATE"
  | "UNABLE_TO_COLLECT_GENDER"
  | "UNABLE_TO_COLLECT_DOCUMENT_NUMBER"
  | "UNABLE_TO_COLLECT_PERSONAL_NUMBER"
  | "UNABLE_TO_COLLECT_NATIONALITY"
  | "UNABLE_TO_COLLECT_HOME_ADDRESS"
  | "DOCUMENT_AND_FACE_IMAGE_MISSING";

export type VerificationSubStatusCategory = EmphasisBy<VerificationSubStatuses>;

export type ProofOfAddressStatuses =
  | "NOT_STARTED"
  | "STARTED"
  | "PENDING_REVIEW"
  | "POSITIVE"
  | "EXEMPT";

type ProofOfAddressStatusCategory = EmphasisBy<ProofOfAddressStatuses>;

export type SanctionsStatuses =
  | "NOT_STARTED"
  | "ACCEPT"
  | "PENDING_REVIEW"
  | "REJECT";

type SanctionsStatusCategory = EmphasisBy<SanctionsStatuses>;

export type CkaStatuses =
  | "PASSED"
  | "FAILED"
  | "EXPIRED"
  | "NOT_TAKEN"
  | "EXEMPT";

type CkaStatusCategory = EmphasisBy<CkaStatuses>;

export type AiStatuses = "UNDECLARED" | "AI" | "NON_AI" | "EXPIRED";

type AiStatusCategory = EmphasisBy<AiStatuses>;

export type CrsStatuses = "UNDECLARED" | "DECLARED";

type CrsStatusCategory = EmphasisBy<CrsStatuses>;

export type MambuTransactionHoldStatus = "PENDING" | "REVERSED" | "EXPIRED";

type HoldStatusCategory = EmphasisBy<MambuTransactionHoldStatus>;

export type FnaStatus = "PASSED" | "NOT_STARTED" | "NOT_SUITABLE";

export type EddStatus =
  | "NOT_STARTED"
  | "EXEMPT"
  | "PENDING_REVIEW"
  | "PASSED"
  | "FAILED"
  | "DATA_SUBMISSION_REQUIRED"
  | "UNKNOWN";

export type AdverseMediaStatus =
  | "AUTO_ACCEPT"
  | "NOT_STARTED"
  | "PENDING_REVIEW"
  | "ACCEPT"
  | "REJECT";

export type PepScreeningStatus =
  | "AUTO_ACCEPT"
  | "NOT_STARTED"
  | "PENDING_REVIEW"
  | "ACCEPT"
  | "REJECT";

export type CmcStatus = "ACCEPT" | "REJECTED" | "PENDING";

type CmcCategory = EmphasisBy<CmcStatus>;

type AdverseMediaCategory = EmphasisBy<AdverseMediaStatus>;

type EddCategory = EmphasisBy<EddStatus>;

type PepScreeningCategory = EmphasisBy<PepScreeningStatus>;

type FnaStatusCategory = EmphasisBy<FnaStatus>;

export const emphasisByStatuses: {
  verification_status: VerificationStatusCategory;
  verification_sub_status: VerificationSubStatusCategory;
  proof_of_address_status: ProofOfAddressStatusCategory;
  sanctions_status: SanctionsStatusCategory;
  cka_status: CkaStatusCategory;
  crs_status: CrsStatusCategory;
  ai_status: AiStatusCategory;
  hold_status: HoldStatusCategory;
  fnaStatus: FnaStatusCategory;
  cmc_status: CmcCategory;
  adverse_media_status: AdverseMediaCategory;
  edd_status: EddCategory;
  pep_screening_status: PepScreeningCategory;
} = {
  verification_status: {
    NOT_STARTED: "secondary",
    STARTED: "warning",
    PENDING_REVIEW: "warning",
    POSITIVE: "success",
    NEGATIVE: "danger",
    RESUBMISSION_REQUIRED: "danger",
  },
  verification_sub_status: {
    SUSPICIOUS_BEHAVIOR: "danger",
    KNOWN_FRAUD: "danger",
    RESTRICTED_IP_LOCATION: "danger",
    MULTIPLE_PARTIES_PRESENT_IN_SESSION: "danger",
    VELOCITY_OR_ABUSE_DUPLICATED_USER: "danger",
    VELOCITY_OR_ABUSE_DUPLICATED_ID: "danger",
    VELOCITY_OR_ABUSE_DUPLICATED_DEVICE: "danger",
    ATTEMPTED_DECEIT: "danger",
    ATTEMPTED_DECEIT_DEVICE_SCREEN_USED: "danger",
    ATTEMPTED_DECEIT_PRINTOUT_USED: "danger",
    ATTEMPTED_DECEIT_DEVICE_SCREEN_USED_FOR_FACE_IMAGE: "danger",
    ATTEMPTED_DECEIT_PHOTOS_STREAMED: "danger",
    SUSPECTED_DOCUMENT_TAMPERING: "danger",
    DOCUMENT_TAMPERED_DATA_CROSS_REFERENCE: "danger",
    DOCUMENT_TAMPERED_DOCUMENT_SIMILAR_TO_SPECIMEN: "danger",
    PERSON_SHOWING_DOCUMENT_MISMATCH: "danger",
    VIDEO_OR_PHOTO_MISSING: "danger",
    POOR_IMAGE_QUALITY: "danger",
    VIDEO_MISSING: "danger",
    FACE_IMAGE_MISSING: "danger",
    FACE_NOT_CLEAR: "danger",
    DOCUMENT_DAMAGED: "danger",
    DOCUMENT_TYPE_NOT_SUPPORTED: "danger",
    DOCUMENT_EXPIRED: "danger",
    DOCUMENTS_PERSON_MISMATCH: "danger",
    DOCUMENT_FRONT_MISSING: "danger",
    DOCUMENT_BACK_MISSING: "danger",
    DOCUMENT_FRONT_NOT_FULLY_IN_FRAME: "danger",
    DOCUMENT_BACK_NOT_FULLY_IN_FRAME: "danger",
    DOCUMENT_DATA_NOT_VISIBLE: "danger",
    UNABLE_TO_COLLECT_SURNAME: "danger",
    UNABLE_TO_COLLECT_FIRST_NAME: "danger",
    UNABLE_TO_COLLECT_DOB: "danger",
    UNABLE_TO_COLLECT_ISSUE_DATE: "danger",
    UNABLE_TO_COLLECT_EXPIRY_DATE: "danger",
    UNABLE_TO_COLLECT_GENDER: "danger",
    UNABLE_TO_COLLECT_DOCUMENT_NUMBER: "danger",
    UNABLE_TO_COLLECT_PERSONAL_NUMBER: "danger",
    UNABLE_TO_COLLECT_NATIONALITY: "danger",
    UNABLE_TO_COLLECT_HOME_ADDRESS: "danger",
    DOCUMENT_AND_FACE_IMAGE_MISSING: "danger",
  },
  proof_of_address_status: {
    NOT_STARTED: "secondary",
    STARTED: "info",
    PENDING_REVIEW: "warning",
    POSITIVE: "success",
    EXEMPT: "success",
  },
  sanctions_status: {
    NOT_STARTED: "secondary",
    ACCEPT: "success",
    PENDING_REVIEW: "warning",
    REJECT: "danger",
  },
  cka_status: {
    PASSED: "success",
    FAILED: "danger",
    EXPIRED: "warning",
    NOT_TAKEN: "secondary",
    EXEMPT: "success",
  },
  ai_status: {
    UNDECLARED: "secondary",
    AI: "warning",
    NON_AI: "success",
    EXPIRED: "danger",
  },
  crs_status: {
    UNDECLARED: "secondary",
    DECLARED: "success",
  },
  hold_status: {
    PENDING: "warning",
    REVERSED: "secondary",
    EXPIRED: "danger",
  },
  fnaStatus: {
    PASSED: "success",
    NOT_STARTED: "secondary",
    NOT_SUITABLE: "danger",
  },
  cmc_status: {
    ACCEPT: "success",
    REJECTED: "danger-outline",
    PENDING: "secondary",
  },
  adverse_media_status: {
    AUTO_ACCEPT: "success",
    ACCEPT: "success",
    PENDING_REVIEW: "warning",
    REJECT: "danger",
    NOT_STARTED: "secondary",
  },
  pep_screening_status: {
    AUTO_ACCEPT: "success",
    ACCEPT: "success",
    PENDING_REVIEW: "warning",
    REJECT: "danger",
    NOT_STARTED: "secondary",
  },
  edd_status: {
    NOT_STARTED: "secondary",
    PASSED: "success",
    EXEMPT: "success",
    PENDING_REVIEW: "warning",
    FAILED: "danger",
    DATA_SUBMISSION_REQUIRED: "primary",
    UNKNOWN: "primary",
  },
};

export type StatusCategory = keyof typeof emphasisByStatuses;

export const allStatusCategories = Object.keys(
  emphasisByStatuses
) as StatusCategory[];

export const getValuesByStatusCategory = (sc: StatusCategory) =>
  Object.keys(emphasisByStatuses[sc]);

export enum StatusBadgeTextEnum {
  NOT_STARTED = "NOT STARTED",
  STARTED = "STARTED",
  PENDING_REVIEW = "PENDING REVIEW",
  POSITIVE = "POSITIVE",
  EXEMPT = "EXEMPT",
  NEGATIVE = "NEGATIVE",
  NON_AI = "NON-AI",
  RESUBMISSION_REQUIRED = "RESUBMISSION REQUIRED",
  PASSED = "PASSED",
  REJECT = "REJECT",
  VERIFIED = "VERIFIED",
  UNVERIFIED = "UNVERIFIED",
  SCREENED = "SCREENED",
  UNSCREENED = "UNSCREENED",
  FAILED = "FAILED",
  AI = "AI",
  DECLARED = "DECLARED",
  UNDECLARED = "UNDECLARED",
  EXPIRED = "EXPIRED",
  EXEMPTED = "EXEMPTED",
  NOT_TAKEN = "NOT TAKEN",
  ACCEPT = "ACCEPT",
  REJECTED = "REJECTED",
  NOT_SUITABLE = "NOT SUITABLE",
  PENDING = "PENDING",
  DATA_SUBMISSION_REQUIRED = "DATA SUBMISSION REQUIRED",
  UNKNOWN = "UNKNOWN",
  AUTO_ACCEPT = "AUTO ACCEPT",
}

export const filterByType = {
  verification_status: "Verification status",
  verification_sub_status: "Verification sub-status",
  proof_of_address_status: "Proof of address status",
  sanctions_status: "Sanctions status",
  cka_status: "CKA status",
  ai_status: "AI status",
  crs_status: "CRS status",
  hold_status: "Hold status",
  fnaStatus: "FNA status",
  cmc_status: "CMC status",
  edd_status: "EDD status",
  adverse_media_status: "Adverse media status",
  pep_screening_status: "PEP screening status",
};

export enum FilterUserListEnum {
  PHYSICAL_DOCUMENT_NOT_USED = "Physical document not used",
  SUSPECTED_DOCUMENT_TAMPERING = "Suspected document tampering",
  DOCUMENTS_PERSON_MISMATCH = "Document/Person mismatch",
  DOCUMENT_EXPIRED = "Document expired",
  NAME_ENTERED_NOT_MATCH = "“Name mismatch",
  SUSPICIOUS_BEHAVIOR = "Suspicious behaviour",
  KNOWN_FRAUD = "Known Fraud",
  VELOCITY_OR_ABUSE_DUPLICATED_USER = "Duplicated user",
  VELOCITY_OR_ABUSE_DUPLICATED_DEVICE = "Duplicated device",
  VELOCITY_OR_ABUSE_DUPLICATED_ID = "Duplicated ID",
  RESTRICTED_IP_LOCATION = "Restricted IP",
  VIDEO_OR_PHOTO_MISSING = "Video or photo missing",
  FACE_NOT_CLEAR = "Face not clear",
  DOCUMENT_DATA_NOT_VISIBLE = "Document data not visible",
  POOR_IMAGE_QUALITY = "Poor image quality",
  DOCUMENT_DAMAGED = "Document damaged",
  DOCUMENT_TYPE_NOT_SUPPORTED = "Document not supported",
  MULTIPLE_PARTIES_PRESENT_IN_SESSION = "Multiple users detected",
  ATTEMPTED_DECEIT = "Attempted deceit",
  ATTEMPTED_DECEIT_DEVICE_SCREEN_USED = "Image from device",
  ATTEMPTED_DECEIT_PRINTOUT_USED = "Image from printout",
  DOCUMENT_TAMPERED_DATA_CROSS_REFERENCE = "Document tampered (data mismatch)",
  DOCUMENT_TAMPERED_DOCUMENT_SIMILAR_TO_SPECIMEN = "Document tampered (similar to specimen)",
  PERSON_SHOWING_DOCUMENT_MISMATCH = "Person showing document mismatch",
  ATTEMPTED_DECEIT_DEVICE_SCREEN_USED_FOR_FACE_IMAGE = "Image from device",
  ATTEMPTED_DECEIT_PHOTOS_STREAMED = "Photos streamed",
  VIDEO_MISSING = "Video missing",
  FACE_IMAGE_MISSING = "Face image missing",
  DOCUMENT_FRONT_MISSING = "Document front missing",
  DOCUMENT_BACK_MISSING = "Document back missing",
  DOCUMENT_FRONT_NOT_FULLY_IN_FRAME = "Document front not in frame",
  DOCUMENT_BACK_NOT_FULLY_IN_FRAME = "Document back not in frame",
  UNABLE_TO_COLLECT_SURNAME = "Cannot collect surname",
  UNABLE_TO_COLLECT_FIRST_NAME = "Cannot collect first name",
  UNABLE_TO_COLLECT_DOB = "Cannot collect birthday",
  UNABLE_TO_COLLECT_ISSUE_DATE = "Cannot collect issue date",
  UNABLE_TO_COLLECT_EXPIRY_DATE = "Cannot collect expiry date",
  UNABLE_TO_COLLECT_GENDER = "Cannot collect gender",
  UNABLE_TO_COLLECT_DOCUMENT_NUMBER = "Cannot collect document number",
  UNABLE_TO_COLLECT_PERSONAL_NUMBER = "Cannot collect ID number",
  UNABLE_TO_COLLECT_NATIONALITY = "Cannot collect nationality",
  UNABLE_TO_COLLECT_HOME_ADDRESS = "Cannot collect address",
  DOCUMENT_AND_FACE_IMAGE_MISSING = "Document and face image missing",
  NOT_STARTED = "Not started",
  STARTED = "Started",
  PENDING_REVIEW = "Pending review",
  POSITIVE = "Positive",
  EXEMPT = "Exempt",
  NEGATIVE = "Negative",
  NON_AI = "Non AI",
  RESUBMISSION_REQUIRED = "Resubmission required",
  PASSED = "Passed",
  REJECT = "Reject",
  VERIFIED = "Verified",
  UNVERIFIED = "Unverified",
  SCREENED = "Screened",
  UNSCREENED = "Unscreened",
  FAILED = "Failed",
  AI = "AI",
  DECLARED = "Declared",
  UNDECLARED = "Undeclared",
  EXPIRED = "Expired",
  EXEMPTED = "Exampted",
  NOT_TAKEN = "Not taken",
  ACCEPT = "Accept",
}

export const allFilterStatusCategories = [
  "verification_status",
  "proof_of_address_status",
  "sanctions_status",
] as StatusCategory[];

export const HTTP_CODES = {
  NOT_FOUND: 404,
  CLIENT_ERROR: 400,
};
