import { useCallback, useState } from "react";
import { useQuery } from "react-query";

import SearchBar from "../components/Search";
import UserListTable from "../components/UserList/UserListTable";
import { getUsers, SearchQuery } from "../services/userServices";
import { UsersType } from "../types/user";
import PaginationComponent from "../components/Pagination";
import { PAGE_SIZE_DEFAULT } from "../constants";

import styles from "../styles/users.module.scss";

const Users = () => {
  const [searchQuery, setSearchQuery] = useState<SearchQuery>();

  const [currentPage, setCurrentPage] = useState(1);

  const { isLoading, error, data } = useQuery<UsersType, Error>(
    ["users", currentPage, searchQuery],
    async () =>
      await getUsers({
        page: currentPage,
        pageSize: PAGE_SIZE_DEFAULT,
        searchQuery,
      })
  );

  const onClickSearch = useCallback((query) => {
    setCurrentPage(1);
    setSearchQuery(query);
  }, []);

  return (
    <div>
      <p className={styles.pageTitle}>Users</p>
      <SearchBar
        searchQuery={searchQuery}
        setSearchQuery={onClickSearch}
        resultFound={data?.pageInfo?.totalCount}
      />
      <div className={styles.container}>
        {data?.pageInfo && (
          <PaginationComponent
            pageCount={Math.ceil(
              data.pageInfo.totalCount / data.pageInfo.pageSize
            )}
            currentPage={currentPage}
            itemOfPage={data.users.length}
            totalItems={data.pageInfo.totalCount}
            containerClass={styles.userListTablePagination}
            setCurrentPage={setCurrentPage}
          />
        )}
        <UserListTable data={data} error={error} isLoading={isLoading} />
        {data?.pageInfo && (
          <PaginationComponent
            pageCount={Math.ceil(
              data.pageInfo.totalCount / data.pageInfo.pageSize
            )}
            currentPage={currentPage}
            itemOfPage={data.users.length}
            totalItems={data.pageInfo.totalCount}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </div>
  );
};

export default Users;
