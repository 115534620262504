import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import StatusBadge from "../../StatusBadge";
import { emphasisByStatuses, StatusBadgeTextEnum } from "../../../constants";
import { UsersType, UserType } from "../../../types/user";
import ErrorPage from "../../ErrorPage";
import PageLoader from "../../PageLoader";

import styles from "./index.module.scss";

type Props = {
  data?: UsersType;
  error?: Error | null;
  isLoading: boolean;
};

const UserListTable = ({ data, error, isLoading }: Props) => {
  const navigate = useNavigate();

  if (isLoading)
    return <PageLoader isLoading={isLoading} content="Loading user..." />;

  if (error || !data) return <ErrorPage />;

  return (
    <div>
      <div className={styles.table}>
        <Table hover bordered responsive="md">
          <thead>
            <tr>
              <th rowSpan={2}>Full name & email</th>
              <th>Mobile</th>
              <th>ID Number</th>
              <th>Verification</th>
              <th>Sanctions</th>
              <th>CKA</th>
              <th>AI</th>
              <th>CRS</th>
              <th>Proof of address</th>
            </tr>
          </thead>
          <tbody>
            {data.users.length > 0 ? (
              data.users.map((user: UserType) => (
                <tr
                  key={user.id}
                  onClick={() => {
                    navigate(`/users/${user.id}`);
                  }}
                  className={styles.tableRow}
                >
                  <td>
                    <div className={styles.fullname}>{user.full_name}</div>
                    <div className={styles.email}>{user.email}</div>
                  </td>
                  <td>{`+${user.mobile_country_code}${user.mobile}`}</td>
                  <td>{user.id_number || "NOT GIVEN"}</td>
                  <td>
                    <StatusBadge
                      status={StatusBadgeTextEnum[user.verification_status]}
                      emphasis={
                        emphasisByStatuses.verification_status[
                          user.verification_status
                        ]
                      }
                    />
                  </td>
                  <td>
                    <StatusBadge
                      status={StatusBadgeTextEnum[user.sanctions_status]}
                      emphasis={
                        emphasisByStatuses.sanctions_status[
                          user.sanctions_status
                        ]
                      }
                    />
                  </td>
                  <td>
                    <StatusBadge
                      status={StatusBadgeTextEnum[user.cka_status]}
                      emphasis={emphasisByStatuses.cka_status[user.cka_status]}
                    />
                  </td>
                  <td>
                    <StatusBadge
                      status={StatusBadgeTextEnum[user.ai_status]}
                      emphasis={emphasisByStatuses.ai_status[user.ai_status]}
                    />
                  </td>
                  <td>
                    <StatusBadge
                      status={StatusBadgeTextEnum[user.crs_status]}
                      emphasis={emphasisByStatuses.crs_status[user.crs_status]}
                    />
                  </td>
                  <td>
                    <StatusBadge
                      status={StatusBadgeTextEnum[user.proof_of_address_status]}
                      emphasis={
                        emphasisByStatuses.proof_of_address_status[
                          user.proof_of_address_status
                        ]
                      }
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className={styles.emptyTable}>
                  Oops, no results were found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default UserListTable;
