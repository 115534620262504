import { useState } from "react";
import { Storage } from "aws-amplify";
import { Table, Button, Modal, Image } from "react-bootstrap";

import styles from "./index.module.scss";

interface TableProps<Row> {
  headers: string[];
  data: Row[];
  selectedTable: string;
}

const blackListedHeaders = ["user_id"];

export default function DynamicTable<Row extends Record<string, string>>(
  props: TableProps<Row>
) {
  const [isFetchingImage, setIsFetchingImage] = useState(false);
  const [showModal, setShowModal] = useState({ visible: false, src: "" });

  if (!props.data || props.data.length === 0) {
    return null;
  }

  const displayImage = async (url: string) => {
    setIsFetchingImage(true);
    const signedUrl = await Storage.get(url);
    setShowModal({ visible: true, src: signedUrl });
    setIsFetchingImage(false);
  };

  const closeImage = () => {
    setShowModal({ visible: false, src: "" });
  };

  const headers = props.headers.filter(
    (header) => !blackListedHeaders.includes(header)
  );

  return (
    <div className={styles.table}>
      <Table hover bordered responsive="sm">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data.map((record: Row, index) => (
            <tr key={index}>
              {headers.map((key, index) => {
                if (
                  key === "front_image_url" ||
                  key === "back_image_url" ||
                  key === "selfie_image_url"
                ) {
                  return (
                    <td key={key + index}>
                      {record[key] ? (
                        <Button
                          size="sm"
                          disabled={isFetchingImage}
                          onClick={() => displayImage(record[key])}
                        >
                          {isFetchingImage ? "Loading" : "Click to View"}
                        </Button>
                      ) : (
                        "No image"
                      )}
                    </td>
                  );
                }
                return <td>{record[key]}</td>;
              })}
            </tr>
          ))}
        </tbody>
        <Modal show={showModal.visible} onHide={closeImage}>
          <Image src={showModal.src} />
        </Modal>
      </Table>
    </div>
  );
}
