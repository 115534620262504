import { Alert } from "react-bootstrap";
import styles from "./index.module.scss";

export default function ErrorPage() {
  return (
    <div className={styles.errorPage}>
      <Alert variant="warning">
        <Alert.Heading>Something went wrong!</Alert.Heading>
        <p>
          Please reload the page and try again. If the problem persists, please
          contact the dev team.
        </p>
      </Alert>
    </div>
  );
}
