import { AuthenticationTest, Address } from "../types";
import { DateTime } from "luxon";
import getSymbolFromCurrency from "currency-symbol-map";
import { DEFAULT_CURRENCY } from "../constants";

export const formatAddress = ({
  country,
  postalCode,
  addressLine1,
  addressLine2,
  addressLine3,
}: Address) => {
  let address = "";

  address += addressLine1 || "";
  address += `${address ? ", " : ""}${addressLine2 || ""}`;
  address += `${address ? ", " : ""}${addressLine3 || ""}`;
  address += `${address ? ", " : ""}${postalCode || ""}`;
  address += `${address ? ", " : ""}${country || ""}`;

  return address || "Not Given";
};

export const getListItemTypeForDocumentAuth = (
  authenticationTest: AuthenticationTest
) => {
  if (authenticationTest.result === "ATTENTION") {
    return "warning";
  }

  if (authenticationTest.result === "FAILED") {
    return "danger";
  }
  return "success";
};

export const getListItemTypeForIdv = (result: string) => {
  if (result.includes("PARTIAL_MATCH")) {
    return "warning";
  }

  if (result.includes("NOT_MATCH")) {
    return "danger";
  }
  return "success";
};

export const formatDate = (
  date: string,
  format = "yyyy-MM-dd",
  timezone?: string
) => {
  return timezone
    ? DateTime.fromISO(date).setZone(timezone).toFormat(format)
    : DateTime.fromISO(date).toFormat(format);
};

export const getCurrencySymbol = (currencyCode = DEFAULT_CURRENCY) => {
  return getSymbolFromCurrency(currencyCode)!;
};

export const formatDecimalNumber = (input: number) => {
  const [naturalPart, decimalPart] = input.toString().split(".");
  const formattedNaturalPart = Number(naturalPart).toLocaleString();
  if (!!decimalPart) return [formattedNaturalPart, decimalPart].join(".");
  return formattedNaturalPart;
};
