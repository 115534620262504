import { useState, memo, useEffect, KeyboardEvent } from "react";
import { Dropdown, Row, Col, Button } from "react-bootstrap";

import {
  filterByType,
  getValuesByStatusCategory,
  StatusCategory,
  FilterUserListEnum,
  allFilterStatusCategories,
} from "../../constants";
import { SearchQuery } from "../../services/userServices";

import "./index.scss";

type SearchBarProps = {
  setSearchQuery: (arg?: SearchQuery) => void;
  searchQuery?: SearchQuery;
  resultFound?: number;
};

enum SearchByEnum {
  NAME = "Name",
  ID = "ID",
  MOBILE = "Mobile",
  EMAIL = "Email",
}

function SearchBar(props: SearchBarProps) {
  const [searchText, setSearchText] = useState("");

  const [searchBy, setSearchBy] = useState<SearchByEnum>(SearchByEnum.MOBILE);

  const [selectedFilter, setSelectedFilter] = useState<StatusCategory | null>(
    null
  );

  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  useEffect(() => {
    if (selectedFilter) {
      setSelectedValue(null);
    }
  }, [selectedFilter]);

  const onChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      props.setSearchQuery({
        [searchBy.toLowerCase()]: searchText,
        ...(selectedFilter &&
          selectedValue && {
            [selectedFilter as string]: selectedValue,
          }),
      });
    }
  };

  const clearAll = () => {
    setSearchText("");
    props.setSearchQuery(undefined);
    setSelectedFilter(null);
    setSelectedValue(null);
  };

  return (
    <Col>
      <div className="d-flex">
        <div>
          <div className="mb-1 title-text">Search by:</div>
          <Row className="nowrap align-items-md-center m-0">
            <div className="input-group searchbox">
              <Dropdown className="search-dropdown">
                <Dropdown.Toggle
                  variant="primary"
                  className="search-toggle"
                  data-testid="select-search-type"
                >
                  <span>{searchBy}</span>
                  <span className="material-icons material-icons-outlined">
                    chevron_right
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Object.values(SearchByEnum).map((searchOption) => (
                    <Dropdown.Item
                      key={searchOption}
                      onClick={() => setSearchBy(searchOption)}
                    >
                      {searchOption}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Row className="nowrap search-input align-items-md-center p-1">
                <span className="search-icon material-icons material-icons-outlined">
                  search
                </span>
                <input
                  data-testid="search-input"
                  type="text"
                  className="form-control p-0 bg-transparent flex-fill"
                  placeholder="Enter search term"
                  aria-label="Enter search term"
                  value={searchText}
                  onChange={onChangeSearchText}
                  onKeyDown={handleKeyDown}
                />
              </Row>
            </div>
            <Button
              className="search-btn"
              data-testid="search-btn"
              variant="success"
              size="lg"
              onClick={() => {
                props.setSearchQuery({
                  ...(searchText && {
                    [searchBy.toLowerCase()]: searchText,
                  }),
                  ...(selectedFilter &&
                    selectedValue && {
                      [selectedFilter as string]: selectedValue,
                    }),
                });
              }}
            >
              Search
            </Button>
          </Row>
        </div>
        <div className="filter-container">
          <div className="mb-1 title-text">Filter by:</div>
          <Dropdown className="add-filters-dropdown m-0">
            <Dropdown.Toggle
              variant="primary"
              className="search-toggle"
              id="dropdown-basic"
              data-testid="add-filter-dropdown"
            >
              <span className={selectedFilter ? "" : "title-text"}>
                {selectedFilter
                  ? filterByType[selectedFilter]
                  : "Select filter type"}
              </span>
              <span className="material-icons material-icons-outlined">
                chevron_right
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {allFilterStatusCategories.map((filter) => (
                <Dropdown.Item
                  key={filter}
                  data-testid={`add-filter-value-${filter}`}
                  onClick={() => setSelectedFilter(filter)}
                >
                  {filterByType[filter]}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="select-value-dropdown m-0">
            <Dropdown.Toggle
              variant="primary"
              className={
                !selectedFilter ? "disable-btn search-toggle" : "search-toggle"
              }
              id="dropdown-basic"
              disabled={!selectedFilter}
              data-testid="select-value-dropdown"
            >
              <span>
                {selectedValue
                  ? FilterUserListEnum[
                      selectedValue as keyof typeof FilterUserListEnum
                    ]
                  : "Select value"}
              </span>
              <span className="material-icons material-icons-outlined">
                chevron_right
              </span>
            </Dropdown.Toggle>
            {selectedFilter && (
              <Dropdown.Menu className="dropdownMenuItem">
                {getValuesByStatusCategory(selectedFilter).map((value) => (
                  <Dropdown.Item
                    key={value}
                    data-testid={`select-value-${value}`}
                    onClick={() => {
                      setSelectedValue(value);
                    }}
                  >
                    {
                      FilterUserListEnum[
                        value as keyof typeof FilterUserListEnum
                      ]
                    }
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>
      </div>
      {props.searchQuery && (
        <>
          <hr className="divider" />
          <Row
            className="nowrap search-result align-items-md-center"
            data-testid="search-result"
          >
            <span>
              {props.resultFound}{" "}
              {props.resultFound === 1 ? "result" : "results"} found
            </span>
            <Row
              className="nowrap flex-full align-items-md-center clear-btn"
              onClick={clearAll}
              data-testid="clear-btn"
            >
              <span className="material-icons material-icons-outlined">
                close
              </span>
              <span>Clear search or filters</span>
            </Row>
          </Row>
        </>
      )}
    </Col>
  );
}

export default memo(SearchBar);
