import { Container } from "react-bootstrap";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import UserDetails from "../components/UserDetails";
import UserTabDetail from "../components/UserDetails/UserTabDetail";
import ErrorPage from "../components/ErrorPage";
import { getUser } from "../services/userServices";
import { UserType } from "../types/user";

import styles from "../styles/user.module.scss";
import PageLoader from "../components/PageLoader";
import StatusBadge from "../components/StatusBadge";
import MambuDetail from "../components/UserDetails/MambuDetail";
import DisableAccountModal from "../components/UserDetails/DisableAccountModal";
import copyIcon from "../assets/icons/copy.svg";
import verifyIcon from "../assets/icons/verify.svg";

export default function User() {
  const { userId } = useParams();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);
  const timerRef = useRef<NodeJS.Timer | undefined>();

  const { error, data, isLoading, refetch } = useQuery<UserType, Error>(
    ["get-user-details", userId],
    () => getUser(userId || "")
  );

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    setDisabled(!!data?.is_disabled);
  }, [data]);

  const onCloseModal = () => setIsModalVisible(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setHasCopied(true);
    timerRef.current = setTimeout(() => setHasCopied(false), 1000);
  };

  if (isLoading)
    return <PageLoader isLoading={isLoading} content="Loading users.." />;

  if (error || !data) return <ErrorPage />;

  return (
    <Container className={styles.wrapper}>
      <Link className={styles.backToUserLink} to="/users">
        <span className="material-icons material-icons-outlined">
          chevron_left
        </span>
        Back to all users
      </Link>
      <div className={styles.boxTitleUserDetail}>
        <h2 className={styles.titleUserDetail}>{data.full_name}</h2>
        <div className={styles.titleUserDetailRightBox}>
          {isDisabled ? (
            <span className={styles.disbledUser}>DISABLED</span>
          ) : (
            <div
              className={styles.titleRight}
              onClick={() => setIsModalVisible(true)}
            >
              Disable account
            </div>
          )}
          <MambuDetail userId={userId} />
        </div>
      </div>
      <div className={styles.copyToClipboard}>
        <span>{data.id}</span>
        {hasCopied ? (
          <img
            src={verifyIcon}
            style={{ cursor: "auto" }}
            alt="verify"
            title="Copied"
          />
        ) : (
          <img
            src={copyIcon}
            alt="arrow"
            onClick={() => copyToClipboard(data.id!)}
            title="Copy to clipboard"
          />
        )}
      </div>

      <StatusBadge
        status={data.kyc_passed ? "KYC PASSED" : "KYC NOT PASSED"}
        emphasis={data.kyc_passed ? "success" : "warning"}
      />
      <div className={styles.boxUserDetail}>
        <UserDetails data={data} />
        <UserTabDetail />
      </div>
      <DisableAccountModal
        name={data.full_name}
        phoneNumber={`+${data.mobile_country_code}${data.mobile}`}
        email={data.email}
        onClose={onCloseModal}
        onApproved={() => {
          setDisabled(true);
          refetch();
        }}
        isVisible={isModalVisible}
      />
    </Container>
  );
}
