import { Alert } from "react-bootstrap";

export default function NoDataComponent() {
  return (
    <div className="text-center">
      <Alert variant="warning">
        <Alert.Heading>No Data!</Alert.Heading>
      </Alert>
    </div>
  );
}
