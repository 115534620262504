import { Amplify } from "aws-amplify";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_USER_POOL_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
  Storage: {
    customPrefix: {
      public: "",
      private: "",
      protected: "",
    },
    AWSS3: {
      region: process.env.REACT_APP_USER_POOL_REGION,
      bucket: process.env.REACT_APP_DOCUMENT_BUCKET,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
  },
});
