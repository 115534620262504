import { Outlet, Link, NavLink } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import { ToastContainer } from "react-toastify";

import logo from "../../assets/images/logo.svg";
import styles from "./index.module.scss";

interface LayoutProps {
  signOut?: () => void;
}

const routePath = [
  { pathname: "/", title: "Dashboard" },
  { pathname: "/users", title: "Users" },
];

export default function Layout(props: LayoutProps) {
  return (
    <main className={styles.container}>
      <nav className={styles.navContainer}>
        <ReactNotifications />
        <ToastContainer />
        <Link to="/">
          <img className={styles.logo} src={logo} alt="logo of Chocolate" />
        </Link>
        <span className={styles.navList}>
          {routePath.map(({ pathname, title }, index) => (
            <NavLink
              to={pathname}
              className={styles.navListLabel}
              style={({ isActive }) =>
                isActive ? { color: "#E42082" } : undefined
              }
              end={pathname === "/"}
              key={index}
            >
              <p>{title}</p>
            </NavLink>
          ))}
        </span>
        <Link to="#" className={styles.navLink} onClick={props.signOut}>
          <span className="material-icons material-icons-outlined">logout</span>
          <p className={styles.navLinkLabel}>Sign Out</p>
        </Link>
      </nav>
      <div className={styles.outletContainer}>
        <Outlet />
      </div>
    </main>
  );
}
