import { Button, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { getAliasesByUserId } from "../../../services/userServices";
import { AliasType } from "../../../types/user";
import ErrorPage from "../../ErrorPage";
import PageLoader from "../../PageLoader";
import PaginationComponent from "../../Pagination";
import { useEffect, useMemo, useState } from "react";
import link from "../../../assets/icons/link.svg";
import styles from "./index.module.scss";

import { formatDate } from "../../../helpers/utils";
import AddAliasNameModal from "../AddAliasNameModal";

const sizeOfPage = 10;

export default function AliasTable() {
  const { userId } = useParams();

  const { status, isLoading, error, data } = useQuery<AliasType[], Error>(
    ["get-collection-aliases", userId],
    () => getAliasesByUserId(userId || "")
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [aliasNames, setAliasNames] = useState([] as AliasType[]);

  useEffect(() => {
    if (status === "success" && data?.length) {
      setAliasNames(data);
    }
  }, [data, status]);

  const onCloseModal = () => setIsModalVisible(false);

  const filteredAliases = useMemo(() => {
    if (!!aliasNames?.length) {
      return aliasNames.slice(
        (currentPage - 1) * sizeOfPage,
        currentPage * sizeOfPage
      );
    }
    return [];
  }, [currentPage, aliasNames]);

  const composeAddedBy = (createdAt: string, requestedBy?: string) =>
    requestedBy
      ? `${requestedBy} on ${formatDate(
          createdAt,
          "dd MMM yyyy",
          "Asia/Singapore"
        )}`
      : "";

  if (isLoading)
    return <PageLoader isLoading={isLoading} content="Loading alias names.." />;

  if (error || !data) return <ErrorPage />;

  return (
    <div>
      <div className={styles.boxExternalPagination}>
        <div className={styles.aliasGeneralInfo}>
          <span
            className={styles.listGroupTitle}
          >{`Approved Aliases (${aliasNames.length})`}</span>
          <Button
            className={styles.addBtn}
            data-testid="add-btn"
            variant="success"
            size="lg"
            onClick={() => setIsModalVisible(true)}
          >
            + Add new alias
          </Button>
        </div>
        <PaginationComponent
          pageCount={Math.ceil(aliasNames.length / sizeOfPage)}
          currentPage={currentPage}
          itemOfPage={filteredAliases.length}
          totalItems={aliasNames.length}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div className={styles.table}>
        <Table hover bordered responsive="md">
          <thead>
            <tr>
              <th>Alias</th>
              <th>Added by</th>
              <th>Proof of documents</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredAliases.length > 0 ? (
              filteredAliases.map((alias: AliasType) => (
                <tr key={alias.id}>
                  <td>
                    <b style={{ marginRight: "4px" }}>{alias.knownName}</b>
                  </td>
                  <td>{composeAddedBy(alias.createdAt, alias.requestBy)}</td>
                  <td>
                    {alias.proofOfDocument ? (
                      <>
                        <a
                          className={styles.aliasLink}
                          target="_blank"
                          href={alias.proofOfDocument}
                          rel="noreferrer"
                        >
                          <img src={link} alt="arrow" />
                          <span style={{ marginLeft: "4px" }}>Drive link</span>
                        </a>
                      </>
                    ) : (
                      <>
                        <span>No drive link added </span>
                      </>
                    )}
                  </td>
                  <td></td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className={styles.emptyTable}>
                  Oops, no results were found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <AddAliasNameModal
        onClose={onCloseModal}
        onSubmitted={(newAlias) => {
          setAliasNames([...aliasNames, newAlias]);
        }}
        isVisible={isModalVisible}
      />
    </div>
  );
}
