import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { PAGE_SIZE_DEFAULT, emphasisByStatuses } from "../../../constants";
import { getTransactionHolds } from "../../../services/userServices";
import ErrorPage from "../../ErrorPage";
import PageLoader from "../../PageLoader";
import StatusBadge from "../../StatusBadge";
import PaginationComponent from "../../Pagination";
import {
  formatDate,
  formatDecimalNumber,
  getCurrencySymbol,
} from "../../../helpers/utils";

import styles from "./index.module.scss";
import { MambuAuthorizationHold } from "../../../types";
import { useMemo, useState } from "react";

const sizeOfPage = PAGE_SIZE_DEFAULT;

export default function HoldsTable() {
  const { userId } = useParams();

  const { isLoading, error, data } = useQuery<MambuAuthorizationHold[], Error>(
    ["get-user-holds", userId],
    () => getTransactionHolds(userId || "")
  );
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedHolds = useMemo(() => {
    if (!!data?.length) {
      return data.slice(
        (currentPage - 1) * sizeOfPage,
        currentPage * sizeOfPage
      );
    }
    return [];
  }, [currentPage, data]);

  if (isLoading)
    return <PageLoader isLoading={isLoading} content="Loading holds.." />;

  if (error || !data) return <ErrorPage />;

  return (
    <div>
      <div className={styles.boxExternalPagination}>
        <span className={styles.listGroupTitle}>Holds</span>
        <PaginationComponent
          pageCount={Math.ceil(data.length / sizeOfPage)}
          currentPage={currentPage}
          itemOfPage={paginatedHolds.length}
          totalItems={data.length}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <div className={styles.table}>
        <Table hover bordered responsive="md">
          <thead>
            <tr>
              <th>Id</th>
              <th>Creation time</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {paginatedHolds.length > 0 ? (
              paginatedHolds.map((hold) => {
                const currencySymbol = getCurrencySymbol(hold.amount.currency);
                const amount = +hold.amount.quantity;
                return (
                  <tr key={hold.holdId}>
                    <td>{hold.holdId}</td>
                    <td>
                      {formatDate(hold.timestamp, "yyyy-MM-dd HH:mm:ss ZZZZ")}
                    </td>
                    <td>
                      {amount > 0
                        ? currencySymbol + formatDecimalNumber(amount)
                        : `-${
                            currencySymbol +
                            formatDecimalNumber(Math.abs(amount))
                          }`}
                    </td>
                    <td>
                      {hold.status && (
                        <StatusBadge
                          status={hold.status}
                          emphasis={emphasisByStatuses.hold_status[hold.status]}
                        />
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={9} className={styles.emptyTable}>
                  Oops, no results were found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
