import { memo } from "react";
import ReactPaginate from "react-paginate";

import styles from "./index.module.scss";

type Props = {
  currentPage: number;
  pageCount: number;
  itemOfPage: number;
  totalItems: number;
  setCurrentPage: (page: number) => void;
  containerClass?: string;
};

const PaginationComponent = ({
  currentPage,
  pageCount,
  setCurrentPage,
  itemOfPage,
  totalItems,
  containerClass = "",
}: Props) => {
  return (
    <div className={`${styles.boxPagination} ${containerClass}`}>
      <span className={styles.viewing}>
        Viewing {itemOfPage} of {totalItems}
      </span>
      <div data-testid="pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <span className="material-icons material-icons-outlined">
              chevron_right
            </span>
          }
          onPageChange={({ selected }) => setCurrentPage(selected + 1)}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          forcePage={currentPage - 1}
          pageCount={Math.max(pageCount, 1)}
          previousLabel={
            <span className="material-icons material-icons-outlined">
              chevron_left
            </span>
          }
          activeClassName={styles.active}
          pageClassName={styles.page}
          containerClassName={styles.container}
          disabledClassName={styles.disabled}
          previousClassName={styles.previous}
          nextClassName={styles.next}
        />
      </div>
    </div>
  );
};

export default memo(PaginationComponent);
