import { Buffer } from "buffer";
import qs from "qs";
import axios from "../helpers/axios";
import { SanctionsOutcomeType } from "../screens/sanctionsOutcome";
import { DepositBankDetail } from "../types/depositBankDetail";
import {
  UserType,
  UserMambuDetails,
  VeriffSessionType,
  AuditLogType,
  KycAttributeType,
  AliasType,
} from "../types/user";
import { MambuAuthorizationHold } from "../types";

export type SearchQuery = {
  name?: string;
  id?: string;
  mobile?: string;
};

type GetUsersQuery = {
  page: number;
  pageSize: number;
  searchQuery?: SearchQuery | null;
};

export const getUsers = async ({
  page,
  pageSize,
  searchQuery,
}: GetUsersQuery) => {
  const queryString = page
    ? `?${qs.stringify({ page, pageSize, ...searchQuery })}`
    : "";
  const { data } = await axios.get(`/users${queryString}`);
  return data;
};

export const getUser = async (userId: string): Promise<UserType> => {
  const { data } = await axios.get(`/users/${userId}`);
  return data;
};

export const getDepositBankDetail = async (
  userId: string
): Promise<DepositBankDetail[]> => {
  const { data } = await axios.get(`/users/${userId}/get-deposit-bank-details`);
  return data;
};

export const getUserMambuDetails = async (
  userId: string
): Promise<UserMambuDetails> => {
  const { data } = await axios.get(`/users/${userId}/mambu-details`);
  return data;
};

type VeriffProofOfAddressQuery = {
  url: string;
  headers: Record<string, string>;
};

export const getProofOfAddress = (sessionId: string) =>
  axios
    .get<VeriffProofOfAddressQuery>(`/kyc/proof-of-address-media/${sessionId}`)
    .then(({ data: query }) =>
      axios.get(query.url, {
        headers: query.headers,
        responseType: "arraybuffer",
      })
    )
    .then((response) => {
      console.log(JSON.stringify(response));
      return Buffer.from(response.data, "binary").toString("base64");
    });

export const approveProofOfAddress = (
  sessionId: UserType["session_id"]
): Promise<void> =>
  axios.post(
    "/kyc/approve-proof-of-address",
    {
      sessionId: sessionId,
      decision: "accept",
    },
    { validateStatus: (status) => status === 204 }
  );

export const updateSanctionsOutcome = (
  sessionId: UserType["session_id"],
  sanctionsOutcome: SanctionsOutcomeType
): Promise<void> =>
  axios.post("/kyc/update-sanctions-outcome", {
    sessionId,
    sanctionsOutcome,
  });

export const getVeriffSessions = async (
  userId: string
): Promise<VeriffSessionType[]> => {
  const { data } = await axios.get(`get-veriff-sessions-by-user/${userId}`);
  return data;
};

export const getAliasesByUserId = async (
  userId: string
): Promise<AliasType[]> => {
  const { data } = await axios.get(`users/${userId}/collection-aliases`);
  return data;
};

export const getTransactionHolds = async (
  userId: string
): Promise<MambuAuthorizationHold[]> => {
  const { data } = await axios.get(`/users/${userId}/transaction-holds`);
  return data;
};

export const getAuditLog = async (
  userId: string,
  kycAttribute: KycAttributeType
): Promise<AuditLogType[]> => {
  const { data } = await axios.get(`/kyc/audit/${kycAttribute}/${userId}`);
  return data;
};

export const disableAccount = async (userId: string): Promise<void> => {
  const { data } = await axios.get(`/users/${userId}/disable`);
  return data;
};

export const addCollectionAlias = async (
  userId: string,
  aliasName: string,
  driveLink?: string
): Promise<AliasType> => {
  const { data } = await axios.post(`/users/${userId}/collection-alias`, {
    aliasName,
    proofOfDocument: driveLink,
  });
  return data;
};
