import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { disableAccount } from "../../../services/userServices";

import styles from "./index.module.scss";

interface IDisableAccountModal {
  name?: string;
  email?: string;
  phoneNumber?: string;
  isVisible: boolean;
  onClose: () => void;
  onApproved: () => void;
}

export default function DisableAccountModal({
  name,
  email,
  phoneNumber,
  onClose,
  onApproved,
  isVisible = false,
}: IDisableAccountModal) {
  const { userId } = useParams();

  const mutation = useMutation({
    mutationFn: (userId: string) => disableAccount(userId),
    onSuccess: onApproved,
    onError: (error: Error) => {
      toast.error(
        `An error happened. Please reach out to the engineering team with the following data: error: ${error.message}, userId: ${userId}`
      );
    },
  });

  const onDisableAccount = async () => {
    mutation.mutate(userId || "");
    onClose();
  };

  return (
    <Modal
      id="disableAccountModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isVisible}
      onHide={onClose}
    >
      <Modal.Body>
        <div className={styles.modalContainer}>
          <h2 className={styles.modalTitle}>
            Are you sure you want to disable the account?
          </h2>
          <p className={styles.modalDescription}>Account details:</p>
          {name && <p className={styles.accountInfo}>{name}</p>}
          {phoneNumber && <p className={styles.accountInfo}>{phoneNumber}</p>}
          {email && <p className={styles.accountInfo}>{email}</p>}

          <div className={styles.modalFooter}>
            <Button
              className={styles.submitButton}
              variant="success"
              size="lg"
              onClick={onDisableAccount}
            >
              Yes, disable
            </Button>
            <Button
              className={styles.cancelButton}
              variant="success"
              size="lg"
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
